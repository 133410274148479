import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "components/header";
import Medico from "components/medico";
import Paciente from "components/paciente";
import { IRecetaWithPrescription } from "interfaces/recetas";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import receta from "services/receta";
import { ContainerReceta } from "./styles";
import Prescripcion from "components/prescripcion";

const DetalleRecetaPrescripcion = () => {
  const [detailRecipeWithPrescription, setDetailRecipeWithPrescription] = useState<IRecetaWithPrescription | undefined | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); 
  const params = useParams();

  useEffect(() => {
    getDetailRecipe();
  }, []);

  const getDetailRecipe = useCallback(async () => {
    try {
      const { data } = await receta.getDetailWithPrescripcion(params.hash!);

      if(data && data.prescripcion){
        setDetailRecipeWithPrescription(data) 
      }
      else{
        setDetailRecipeWithPrescription(null);
        setError(true);
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDetailRecipeWithPrescription(null);
      setError(true);
      return error;
    }
  }, []);


  return (
    <Box>
      <ContainerReceta>
        {loading && (
          <div className="ContainerProgress">
            <CircularProgress />
          </div>
        )}
        {!error ? 
        <>
          {!loading && (
            <Header
              emisor={detailRecipeWithPrescription  ? detailRecipeWithPrescription.emisor : ""}
              nroPrestador={detailRecipeWithPrescription  ? detailRecipeWithPrescription.nroPrestador : ""}
              fechaEmision={
                detailRecipeWithPrescription && detailRecipeWithPrescription.fechaEmision !== undefined
                  ? detailRecipeWithPrescription.fechaEmision
                  : ""
              }
              nroReceta={detailRecipeWithPrescription ? detailRecipeWithPrescription.numeroReceta : 0}
              hash={params.hash}
            />
          )}
          {!loading && detailRecipeWithPrescription && detailRecipeWithPrescription.medico && <Medico medico={detailRecipeWithPrescription.medico} />}
          {!loading && detailRecipeWithPrescription && (
            <Paciente
              paciente={detailRecipeWithPrescription.paciente}
              diagnostico={detailRecipeWithPrescription.diagnostico}
              tratamiento={detailRecipeWithPrescription.tratamiento}
              financiador={detailRecipeWithPrescription.financiador}
              codDiagnostico={detailRecipeWithPrescription.codDiagnostico}
            />
          )}
          {!loading && detailRecipeWithPrescription && ( 
            <Prescripcion prescriptions={detailRecipeWithPrescription.prescripcion} imprimirDiagnostico={detailRecipeWithPrescription.imprimirDiagnostico} />
           )} 
        </>: <Header
              emisor={"Desconocido"}
              fechaEmision={""}
              nroReceta={0}
              hash={params.hash}
              nroPrestador={"Desconocido"}
            />
        }
      </ContainerReceta>
    </Box>
  );
};

export default DetalleRecetaPrescripcion;
