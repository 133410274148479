import React from "react";
import { PropsPacienteRecipe } from "./interface";
import { ContainerSection } from "./styles";

const Paciente = ({
  paciente,
  diagnostico,
  codDiagnostico,
  financiador,
  tratamiento,
}: PropsPacienteRecipe) => {

  const lowercaseFinanciador = financiador.toLowerCase();

  return (
    <ContainerSection>
      <div className="DataPaciente">
        <p>
          <span className="Tag">Paciente:</span> {paciente.nombre}{" "}
          {paciente.apellido}
        </p>
        {paciente.tipoDoc && paciente.nroDoc && (
          <p>
            <span className="Tag">{paciente.tipoDoc}:</span> {paciente.nroDoc}
          </p>
        )}
        <p>
          <span className="Tag">Nro. Afiliado:</span>{" "}
          {paciente.cobertura.numero && paciente.cobertura.numero !== 0
            ? paciente.cobertura.numero
            : "-"}
        </p>
        <p>
          <span className="Tag">Financiador:</span>{" "}
          {financiador && financiador !== "" ? financiador : " - "}
        </p>
        {lowercaseFinanciador !== "ioscor" && (
          <p>
            <span className="Tag">Plan:</span>{" "}
            {paciente.cobertura.plan && paciente.cobertura.plan !== ""
              ? paciente.cobertura.plan
              : " - "}
          </p>
        )}
      {paciente.cobertura.nroSiniestro && (
        <p>
          <span className="Tag">Nro Siniestro:</span> {paciente.cobertura.nroSiniestro}
        </p>
      )}
      </div>
      <div className="DataInRecipe">
        <p>
          <span className="Tag">Código CIE-10 / Diagnóstico:</span>{" "}
          {codDiagnostico && codDiagnostico !== "" ? codDiagnostico : " - "}
        </p>
        {/* <p>
          <span className="Tag">Tratamiento prolongado:</span>{" "}
          {tratamiento === 0 ? "No" : "Sí"}
        </p> */}
      </div>
    </ContainerSection>
  );
};

export default Paciente;
