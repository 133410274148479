import React from "react";
import { ContainerSection } from "./styles";
import { PropsPrescripcion } from "./interface";

const Prescripcion = ({ prescriptions, imprimirDiagnostico }: PropsPrescripcion) => {

  const validateParamImprimirDiagnostico = (diagnostico: string, value: string) => {
    if (value === "N") {
      return("-");
    }
    return diagnostico;
  }

  return (
    <ContainerSection>
      <p className="Title">Prescripción</p>
      {prescriptions.map((prescription, index) => (
        <div key={index} className="MedicamentoContent">
          <div className="MedicamentoPrescipto">
            <div className="LineaHeader">
              <p className="Subtitle">Rp/</p>
            </div>
            <div className="BodyMed">
              <div className="LineaDescripcion">
                <p>
                  <span className="Tag">Código CIE-10 / Diagnóstico: </span>
                  {prescription.codDiagnostico && prescription.diagnostico
                    ? `${prescription.codDiagnostico} / ${validateParamImprimirDiagnostico(prescription.diagnostico, imprimirDiagnostico)}`
                    : " - "}
                </p>
              </div>
              <div className="LineaDescripcion">
                <p>
                  <span className="Tag">Prescripción: </span>
                  {prescription.prescripcion}
                </p>
              </div>
              <div className="LineaDescripcion">
                <p>
                  <span className="Tag">Cantidad: </span>
                  {prescription.cantidad}
                </p>
              </div>
              <div className="LineaDescripcion">
                <p>
                  <span className="Tag">Codigo Práctica: </span>
                  {prescription.codPractica}
                </p>
              </div>
              <div className="LineaDescripcion">
                <p>
                  <span className="Tag">Notas: </span>
                  {prescription.notas}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </ContainerSection>
  );
};

export default Prescripcion;
